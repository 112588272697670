import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { getCapacityDtoToCapacity } from './capacity.converters';
import {
    CustomInfiniteQueryOptions,
    CustomMutationOptions,
    CustomQueryOptions,
} from '../common.types';
import {
    CapacitiesService,
    type CreateManyCapacitiesReqItemDto,
} from '../../requests';

// Get all products lazy
interface UseCapacitiesLazyKeyData {
    ids?: Array<string>;
    pageNumber?: number;
    pageSize?: number;
}

export const useCapacitiesLazyKey = 'useCapacitiesLazyKey';

export const useCapacitiesLazy = (
    params: UseCapacitiesLazyKeyData = {},
    options: CustomInfiniteQueryOptions<
        ReturnType<typeof CapacitiesService.getCapacities>
    > & { onInvalidPagination?: () => void } = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const pageSize = params.pageSize || 20;

    const { data, ...other } = useInfiniteQuery({
        queryKey: [useCapacitiesLazyKey, params],
        queryFn: async ({ pageParam }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CapacitiesService.getCapacities(
                authorization,
                params.ids,
                pageParam?.page || 0,
                pageSize,
                pageParam?.startAtId || undefined
            );
        },
        getNextPageParam: lastPage => {
            if (!lastPage.items || !lastPage.next_id) return undefined;
            return {
                startAtId: lastPage.next_id,
                page: lastPage.next_page_number,
            };
        },
        ...options,
        onError: (error: any) => {
            if (error?.status === 409) {
                options.onInvalidPagination?.();
            } else options.onError?.(error);
        },
    });

    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          (page.items || []).map(getCapacityDtoToCapacity)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

const useCapacitiesKey = 'useCapacitiesKey';

export const useCapacities = (
    ids: string[] = [],
    options: CustomQueryOptions<
        ReturnType<typeof CapacitiesService.getCapacities>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: [useCapacitiesKey, ids],
        enabled: !!ids.length,
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CapacitiesService.getCapacities(authorization, ids, 0, 100);
        },
        ...options,
    });
};

const useCapacityKey = 'useCapacityKey';

export const useCapacity = (
    id: string,
    options: CustomQueryOptions<
        ReturnType<typeof CapacitiesService.getCapacity>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();

    const { data, ...rest } = useQuery({
        queryKey: [useCapacityKey, id],
        enabled: !!id,
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CapacitiesService.getCapacity(id, authorization);
        },
        ...options,
    });

    const parsedData = useMemo(
        () => (data ? getCapacityDtoToCapacity(data) : undefined),
        [data]
    );

    return {
        data: parsedData,
        ...rest,
    };
};

export const useCreateCapacity = (
    options: CustomMutationOptions<
        CreateManyCapacitiesReqItemDto,
        ReturnType<typeof CapacitiesService.createManyCapacities>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async capacity => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CapacitiesService.createManyCapacities(authorization, {
                items: [capacity],
            });
        },
        ...options,
        onSuccess: (...args) => {
            // TODO invalidate capacity list
            options?.onSuccess?.(...args);
        },
    });
};
