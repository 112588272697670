import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    CreatableTags,
    PaxPicker,
    Select,
    TagsList,
    TextField,
    useForm,
} from '@travelity/form';
import { Box, Chip, Stack, Typography, Tooltip } from '@mui/material';
import {
    AvailableProduct,
    DiscountType,
    useCreateBooking,
    Customer,
    AvailableProductOption,
    RequestTypes,
    useUpdatePrice,
    SourceType,
    DirectSource,
    PersonNameData,
    AvailabilityProduct,
    PaxData,
    ProductType,
    useDayAvailability,
} from '@travelity/api';
import { useTranslation } from 'react-i18next';
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { format } from 'date-fns';
import Button from '@mui/material/Button';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { durationToHumanReadable } from '@travelity/utils';
import { Heading, LoadingOverlay, SquareCard } from '@travelity/ui';
import { useSnackbar } from 'notistack';
import { uniqBy } from 'lodash';
import SelectCapacity from '@travelity/form/src/controls/select-capacity';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import type {
    AgebandPricingData,
    NoteVisibility,
    FlatPricingData,
} from '@travelity/api/src/requests';
import { Lock } from '@mui/icons-material';
import { ProductTypeIcon } from '../../../components/product-type-icon';
import { useIsChanged } from '../../../hooks';
import SelectCustomer from '../../../components/select-customer/select-customer';
import { PriceSummary } from '../../../components/price-summary';
import { FilterPaxValue } from '../../../components/filters/filters.types';
import { RouteTooltip } from '../../../components/route-tooltip/route-tooltip';
import schema from './booking.side-from.schema';
import SelectReferral from '../../../components/select-company/select-referral';
import { useUserContext } from '../../../contexts/user';
import { ProductOptionCards } from '../../../components/product-option-cards';

// TODO all products and the time / day / event
export interface BookingSideFormProps {
    products?: Record<string, AvailableProduct>;
    availableProduct: AvailabilityProduct;
    time: number;
    pax: PaxData;
    changeTime: (v: number) => void;
    onSuccess: () => void;
    discard: () => void;
}

export interface BookingCreationForm {
    options: AvailableProductOption[];
    customer: Customer;
    discountType: DiscountType;
    discountAmount: number;
    time: number;
    event: string;
    pax: FilterPaxValue;
    notes: { value: string; type: NoteVisibility }[];
    sourceType: SourceType;
    sourceName?: DirectSource;
    referral?: {
        id?: string;
        name?: PersonNameData;
        email: string;
        company?: string;
    };
}

export const sourceTypes = [
    {
        value: SourceType.DIRECT,
        label: 'Direct',
    },
    {
        value: SourceType.REFERRAL,
        label: 'Referral',
    },
];

export const directSources = [
    {
        value: DirectSource.EMAIL,
        label: 'Email',
    },
    {
        value: DirectSource.PHONE_CALL,
        label: 'Phone Call',
    },
    {
        value: DirectSource.WALK_IN,
        label: 'Walk-in',
    },
    {
        value: DirectSource.INSTAGRAM,
        label: 'Instagram',
    },
    {
        value: DirectSource.FACEBOOK_MESSENGER,
        label: 'Facebook',
    },
    {
        value: DirectSource.LIVE_CHAT,
        label: 'Live chat',
    },
    {
        value: DirectSource.WEBSITE,
        label: 'Website',
    },
    {
        value: DirectSource.WHATSAPP,
        label: 'WhatsApp',
    },
    {
        value: DirectSource.TELEGRAM,
        label: 'Telegram',
    },
];

const taxSx = {
    bgcolor: '#DDF0F5',
    color: '#2B395B',
    fontSize: '14px',
    fontWeight: 600,
    p: '10px 16px',
    '&.MuiChip-colorPrimary': {
        bgcolor: '#55B5CF',
        color: '#FFFFFF',
        fontWeight: 400,
    },
    '&:hover': {
        bgcolor: '#AAD9E7',
    },
};

const BookingSideForm: React.FC<BookingSideFormProps> = props => {
    const { t } = useTranslation('product');
    const { enqueueSnackbar } = useSnackbar();
    const {
        products: productsDefault,
        availableProduct: availableProductDefault,
        time,
        pax,
        discard,
        onSuccess,
        changeTime,
    } = props;
    const { mutate: createBooking, isLoading: isSaving } = useCreateBooking({
        onSuccess: () => {
            onSuccess();
            enqueueSnackbar(
                `Created a booking for the product "${availableProductDefault.name}"`,
                {
                    variant: 'success',
                }
            );
        },
        onError: (e, params) => {
            let message = `Failed to create the booking for the product "${availableProductDefault.name}"`;
            if ((e as Error)?.message === RequestTypes.ORDER) {
                message = `Failed to create the order for the customer "${params.customer.fullName}"`;
            }
            if ((e as Error)?.message === RequestTypes.EVENT) {
                message = `Failed to create the event for the product "${availableProductDefault.name}"`;
            }
            enqueueSnackbar(message, {
                variant: 'error',
            });
        },
    });

    const [optionsLocal, setOptionsLocal] =
        useState<AvailableProductOption[]>();
    const { data: availability, isFetching: isFetchingDayAvailability } =
        useDayAvailability(
            {
                time,
                pax,
                productId: availableProductDefault.id,
                options: optionsLocal,
            },
            {
                products: productsDefault,
                availableProduct: availableProductDefault,
            },
            {
                onSuccess: d => {
                    if (!d.availableProduct.events.length) {
                        enqueueSnackbar(
                            'No available events for the selected time and option',
                            {
                                variant: 'error',
                            }
                        );
                        discard();
                    } else {
                        const selectedTimeAvailableEvents =
                            d.availableProduct.events.find(
                                ({ time: timestamp }) => timestamp === time
                            );
                        if (!selectedTimeAvailableEvents) {
                            changeTime(d.availableProduct.events[0].time);
                        }
                    }
                },
            }
        );

    const { products, availableProduct } = availability || {
        products: productsDefault,
        availableProduct: availableProductDefault,
    };

    const selectedTimeEvents = useMemo(() => {
        return availableProduct.events
            .filter(({ time: timestamp }) => timestamp === time)
            .sort((a, b) => (!!a.id && !b.id ? 1 : -1));
    }, [availableProduct, time]);

    const eventOptions = useMemo(
        () =>
            selectedTimeEvents.map(event => {
                const product = products?.[event.key as string];

                return {
                    value: event.id || (event.key as string),
                    isNew: !event.id,
                    vacant:
                        event.vacantSeats !== undefined
                            ? event.vacantSeats
                            : product?.vacantSeats || 0,
                    label: `${product?.capacity.name}`,
                };
            }),
        [selectedTimeEvents, products]
    );

    const {
        Form,
        watch,
        setValue,
        formState: { errors },
        trigger,
    } = useForm<BookingCreationForm>({
        schema,
        mode: 'onChange',
        validateInitially: true,
        defaultValues: {
            time,
            pax,
            discountType: DiscountType.RELATIVE,
            sourceType: SourceType.DIRECT,
        },
        onSubmit: useCallback(
            (data: BookingCreationForm, e?: React.BaseSyntheticEvent) => {
                const event = selectedTimeEvents.find(
                    ev => ev.id === data.event || ev.key === data.event
                );

                if (!event) return;
                const eventProduct =
                    event?.key && products ? products[event.key] : undefined;
                const productOptions =
                    data.options?.map(({ pax: optionsPax, id }) => ({
                        id: id as string,
                        customers: {
                            pax: optionsPax,
                            items: [{ customer_id: data.customer.id }],
                        },
                    })) || [];

                createBooking({
                    customer: data.customer,
                    eventId: event.id,
                    notes: data.notes,
                    pax: data.pax,
                    // @ts-ignore
                    isDraft: e?.nativeEvent.submitter.dataset.draft,
                    source: {
                        type: data.sourceType,
                        name: data.sourceName,
                        referral: data.referral,
                    },
                    discount: data.discountAmount
                        ? {
                              type: data.discountType,
                              amount: data.discountAmount,
                          }
                        : undefined,
                    time: new Date(event.time),
                    productId: eventProduct?.id || '',
                    capacityId: eventProduct?.capacity.id || '',
                    productOptions,
                });
            },
            [selectedTimeEvents]
        ),
    });

    const selectedEvent = selectedTimeEvents.find(
        event => event.id === watch('event') || event.key === watch('event')
    );

    const eventProduct =
        selectedEvent?.key && products
            ? products[selectedEvent.key]
            : undefined;

    useEffect(() => {
        const defaultEvent =
            selectedTimeEvents.find(event => event.eventCapacityDefault) ||
            selectedTimeEvents.find(
                event => event.key && products?.[event.key].capacity.default
            ) ||
            selectedTimeEvents[0];
        setValue('event', defaultEvent?.id || defaultEvent?.key || '');
    }, [selectedTimeEvents]);

    const sourceType = watch('sourceType');
    useEffect(() => {
        trigger('referral');
        trigger('sourceName');
    }, [sourceType]);

    const productChanged = useIsChanged(availableProduct.id);
    useEffect(() => {
        if (productChanged) {
            setValue('discountAmount', 0);
            setValue('options', []);
        }
    }, [productChanged, availableProduct.id, pax, DiscountType.RELATIVE]);

    const formTime = watch('time');
    useEffect(() => changeTime(formTime), [formTime]);
    useEffect(() => {
        if (time !== formTime) setValue('time', time);
    }, [time]);

    const availableTimeOptions = useMemo(
        () =>
            uniqBy(availableProduct.events, 'time').map(
                ({ time: timestamp }) => ({
                    value: timestamp,
                    label: format(timestamp, 'HH:mm'),
                })
            ) || [],
        [availableProduct]
    );

    const duration = eventProduct?.duration
        ? durationToHumanReadable(eventProduct.duration)
        : '';

    const stops = useMemo(() => {
        const route = selectedEvent?.route || eventProduct?.route;
        if (!route) return [];
        const list: { date?: Date; name: string }[] = [];

        route.stops.forEach(stop => {
            list.push({ name: stop.name });
        });
        return list;
    }, [availableProduct, selectedEvent]);

    const errorFields = Object.values(errors)
        .map(error => error?.message)
        .filter(v => v) as string[];

    // Discount
    const { user } = useUserContext();
    const discountTypeOptions = useMemo(
        () => [
            { value: 'relative', label: '%' },
            {
                value: 'absolute',
                label: user?.currency || 'Absolute',
            },
        ],
        [user?.currency]
    );

    const selectedOptionsArray = watch('options');
    const selectedOptions =
        selectedOptionsArray && selectedOptionsArray.length
            ? selectedOptionsArray
            : undefined;
    useEffect(() => {
        setOptionsLocal(selectedOptionsArray);
    }, [selectedOptionsArray]);

    // Price calculation
    const discountAmount = watch('discountAmount') || 0;
    const discountType = watch('discountType');
    // const customerId = watch('customer')?.id;
    const priceRequestParams = useMemo(
        () =>
            eventProduct
                ? {
                      productId: availableProduct.id as string,
                      capacityId: eventProduct.capacity.id,
                      time,
                      pax,
                      // customerId,
                      discount: {
                          type: discountType,
                          amount: discountAmount,
                      },
                      currency: eventProduct.pricing?.currency || '',
                      price: eventProduct.pricing?.price as
                          | FlatPricingData
                          | AgebandPricingData,
                      options:
                          selectedOptions
                              ?.filter(o => o.pricing)
                              .map(option => ({
                                  id: option.id as string,
                                  pax: option.pax,
                                  name: option.name,
                                  type: option.type,
                                  price: option.pricing?.price as
                                      | FlatPricingData
                                      | AgebandPricingData,
                              })) || [],
                  }
                : undefined,
        [
            availableProduct.id,
            eventProduct?.capacity.id,
            time,
            // customerId,
            pax,
            discountAmount,
            discountType,
            selectedOptions, // id, pax
        ]
    );

    const {
        data: pricing,
        isLoading: isPriceLoading,
        changed: priceChanged,
        update: updatePrice,
    } = useUpdatePrice(priceRequestParams, eventProduct?.financials);

    return (
        <>
            <Stack sx={{ pl: 2, pr: 0.5, height: 1 }}>
                <Stack
                    direction="row"
                    sx={{ pr: 1.5, pt: 1 }}
                    alignItems="center"
                    gap={1}
                >
                    <Heading
                        sx={{
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '20px',
                            pr: 1,
                        }}
                        ellipsis
                    >
                        {availableProduct.name}
                    </Heading>
                    <Chip
                        sx={{
                            color: '#fff',
                            bgcolor: '#3B4D7D',
                            px: '12px',
                            py: '6px',
                            '& .MuiChip-label': {
                                pl: 0.5,
                                pr: 0,
                            },
                        }}
                        icon={
                            <ProductTypeIcon
                                type={availableProduct.type}
                                shared={availableProduct.shared}
                            />
                        }
                        label={`${
                            availableProduct.type === ProductType.TOUR
                                ? availableProduct.shared
                                    ? 'Group'
                                    : 'Private'
                                : ''
                        } ${t(availableProduct.type)}`}
                    />
                    {!!stops.length && (
                        <RouteTooltip stops={stops}>
                            <Chip
                                color="secondary"
                                sx={{
                                    color: '#36869C',
                                    bgcolor: '#DDF0F5',
                                    px: '12px',
                                    py: '6px',
                                    '& .MuiChip-label': {
                                        pl: 0.5,
                                        pr: 0,
                                    },
                                    '& .MuiChip-icon': {
                                        mx: 0,
                                    },
                                }}
                                icon={
                                    <DirectionsRoundedIcon
                                        sx={{
                                            fontSize: '12px',
                                            color: '#36869C',
                                        }}
                                    />
                                }
                                label="Route"
                            />
                        </RouteTooltip>
                    )}
                </Stack>

                <Stack
                    sx={{
                        grow: 2,
                        pl: 0,
                        pr: 3,
                        pt: '10px',
                        pb: 2,
                        height: '139px',
                    }}
                    direction="row"
                    justifyContent="space-evenly"
                    gap={1}
                >
                    <SquareCard
                        title="Date"
                        value={format(time, 'dd MMM yyyy')}
                        Icon={CalendarMonthIcon}
                    />
                    <SquareCard
                        title="Start Time"
                        value={format(time, 'HH:mm')}
                        Icon={AccessTimeIcon}
                        count={3}
                    />
                    <SquareCard
                        title="Duration"
                        value={duration}
                        Icon={AccessTimeIcon}
                        count={3}
                    />
                </Stack>
                <Form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 2,
                        position: 'relative',
                        height: 'calc(100% - 144px)',
                    }}
                >
                    <Box
                        sx={{
                            minHeight: 0,
                            flexGrow: 2,
                        }}
                    >
                        <PerfectScrollbar>
                            <Stack gap={2} sx={{ py: 1, pr: 1.5 }}>
                                {!!eventProduct?.options?.length && (
                                    <ProductOptionCards
                                        productOptions={eventProduct.options}
                                        selectedOptions={watch('options')}
                                        pax={pax}
                                    />
                                )}
                                <Box>
                                    <Typography
                                        sx={{
                                            color: '#2B395B',
                                            fontSize: '12px',
                                        }}
                                    >
                                        Select Available Time
                                    </Typography>
                                    <TagsList
                                        name="time"
                                        options={availableTimeOptions}
                                        gap={1}
                                        maxItems={15}
                                        tagSx={taxSx}
                                    />
                                </Box>
                                <SelectCapacity
                                    name="event"
                                    label={t('Capacity')}
                                    placeholder={t('Select Capacity')}
                                    options={eventOptions}
                                />
                                <Select
                                    name="sourceType"
                                    label={t('Booking Source Type')}
                                    placeholder={t('Select Source Type')}
                                    options={sourceTypes}
                                />
                                {watch('sourceType') === SourceType.DIRECT ? (
                                    <Select
                                        name="sourceName"
                                        label={t('Booking Source')}
                                        placeholder={t('Select Source')}
                                        options={directSources}
                                    />
                                ) : (
                                    <SelectReferral
                                        name="referral"
                                        label="Booking Source"
                                        placeholder="Type company, email or name"
                                        productId={eventProduct?.id}
                                    />
                                )}
                                <PaxPicker
                                    name="pax"
                                    withDropdown
                                    selectProps={{
                                        disabled: true,
                                        width: '100%',
                                        label: 'PAX',
                                        placeholder: 'Select PAX',
                                    }}
                                />
                                <SelectCustomer
                                    name="customer"
                                    label="Customer Name or Email"
                                    placeholder="Type Customer Name or Email"
                                />
                                <Stack
                                    direction="row"
                                    gap={1}
                                    sx={{
                                        bgcolor: '#FFFFFF',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <TextField
                                        label="Discount"
                                        placeholder="Type Discount"
                                        name="discountAmount"
                                        sx={{ flexGrow: 2 }}
                                    />
                                    <Select
                                        width="140px"
                                        name="discountType"
                                        label=""
                                        options={discountTypeOptions}
                                    />
                                </Stack>
                                <CreatableTags
                                    name="notes"
                                    renderIcon={({ type }) =>
                                        type === 'internal' ? (
                                            <Tooltip title="Internal">
                                                <Lock />
                                            </Tooltip>
                                        ) : null
                                    }
                                    inputProps={{
                                        label: 'Notes',
                                        placeholder:
                                            'Type and press enter to add note',
                                    }}
                                    options={[
                                        {
                                            value: 'internal',
                                            label: 'internal',
                                        },
                                        {
                                            value: 'customer',
                                            label: 'customer',
                                        },
                                    ]}
                                    defaultOption="internal"
                                    chipDirection="column"
                                    chipSx={{
                                        borderRadius: '12px',
                                        padding: '8px',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: '10px',
                                        bgcolor: '#F4F6FA',
                                        '& > .MuiChip-label': {
                                            color: '#2B395B',
                                            fontSize: '12px',
                                            flexGrow: 2,
                                            whiteSpace: 'wrap',
                                        },
                                        '& > .MuiChip-icon ': {
                                            color: '#2B395B',
                                        },
                                    }}
                                />
                            </Stack>
                        </PerfectScrollbar>
                    </Box>
                    <Stack
                        sx={{
                            width: 1,
                            zIndex: 1,
                            pr: 1.5,
                            pb: 2,
                            maxHeight: 1,
                        }}
                    >
                        <PriceSummary
                            pricing={pricing}
                            pax={pax}
                            isLoading={isPriceLoading}
                            priceChanged={priceChanged}
                            updatePrice={updatePrice}
                        />
                        <Stack direction="row" mt={2} gap={1}>
                            <Button
                                variant="contained"
                                color="neutral"
                                sx={{ flexGrow: 1 }}
                                onClick={discard}
                            >
                                Discard
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ flexGrow: 2 }}
                                type="submit"
                                data-draft
                                disabled={
                                    !!errorFields.length ||
                                    isPriceLoading ||
                                    priceChanged
                                }
                            >
                                Save as Draft
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ flexGrow: 1 }}
                                disabled={
                                    !!errorFields.length ||
                                    isPriceLoading ||
                                    priceChanged
                                }
                                type="submit"
                            >
                                Confirm
                            </Button>
                        </Stack>
                    </Stack>
                </Form>
            </Stack>
            {(isSaving || isFetchingDayAvailability) && (
                <LoadingOverlay
                    title="Please wait..."
                    subTitle="It’ll just take a moment."
                />
            )}
        </>
    );
};

export default React.memo(BookingSideForm);
