import React, { useMemo, useRef, useState } from 'react';
import { Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
    Booking,
    bookingFiltersToRequestBodyConverter,
    BookingStatus,
    useBookingsLazy,
} from '@travelity/api';
import { ConfirmationDialog } from '@travelity/ui';
import { BookingListItem } from './components/booking-list-item';

import { BookingPreview } from '../../components/booking-preview';
import { BookingListItemSkeleton } from './components/booking-list-item-skeleton';
import { Filters } from '../../components/filters';
import {
    FilterOption,
    FilterTypes,
} from '../../components/filters/filters.types';
import {
    List,
    ListItems,
    ListMain,
    ListSidebar,
    ListTitle,
} from '../../components/list-layout';
import { useLoadOnScroll } from '../../hooks';
import { SidebarContent } from '../../components/sidebar-content';
import { productTypeOptions } from '../select-type/select-type';
import { useHasFilter } from '../../components/filters/filters.hooks';

const bookingFilters: FilterOption[] = [
    {
        name: 'dates',
        label: 'Dates',
        type: FilterTypes.DATES,
        selectText: 'Please, select the value for date filters',
    },
    {
        name: 'statuses',
        label: 'Statuses',
        type: FilterTypes.DROPDOWN,
        multiple: true,
        selectText: 'Please, select the value for event status',
        options: [
            {
                value: BookingStatus.DRAFT,
                label: 'Draft',
            },
            {
                value: BookingStatus.CANCELLED,
                label: 'Cancelled',
            },
            {
                value: BookingStatus.CONFIRMED,
                label: 'Confirmed',
            },
            {
                value: BookingStatus.HOLD,
                label: 'Hold',
            },
        ],
    },
    {
        name: 'type',
        label: 'Product Type',
        type: FilterTypes.DROPDOWN,
        selectText: 'Please, select the value for product type',
        options: productTypeOptions,
    },
    {
        name: 'source',
        label: 'Source',
        type: FilterTypes.SOURCE,
        selectText: 'Please, select the value for source',
    },
    {
        name: 'orderNumber',
        label: 'Order Number',
        type: FilterTypes.KEYWORD,
        selectText: 'Please, type the order number',
    },
    {
        name: 'createdAt',
        label: 'Created At',
        type: FilterTypes.DATES,
        disable: used =>
            used.includes('updatedAt') ||
            used.includes('updatedBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Please, select the dates for created at filter',
    },
    {
        name: 'createdBy',
        label: 'Created By',
        type: FilterTypes.KEYWORD,
        disable: used =>
            used.includes('updatedAt') ||
            used.includes('updatedBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Type user name or email',
    },
    {
        name: 'updatedAt',
        label: 'Updated At',
        type: FilterTypes.DATES,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Please, select the dates for updated at filter',
    },
    {
        name: 'updatedBy',
        label: 'Updated By',
        type: FilterTypes.KEYWORD,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Type user name or email',
    },
    {
        name: 'deletedAt',
        label: 'Canceled At',
        type: FilterTypes.DATES,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('updatedAt') ||
            used.includes('updatedBy'),
        selectText: 'Please, select the dates for deleted at filter',
    },
    {
        name: 'deletedBy',
        label: 'Canceled By',
        type: FilterTypes.KEYWORD,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('updatedAt') ||
            used.includes('updatedBy'),
        selectText: 'Type user name or email',
    },
    {
        name: 'searchText',
        label: 'Search',
        type: FilterTypes.SEARCH,
        selectText: 'Search product names, route locations, etc...',
    },
];

function Bookings() {
    const { bookingId } = useParams();

    const [filters, setFilters] = useState({});
    const hasFilters = useHasFilter(filters, bookingFilters);

    const containerRef = useRef<HTMLElement>();
    const [showInvalidatePagination, setShowInvalidatePagination] =
        useState(false);

    const {
        data: bookings,
        isLoading,
        refetch,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useBookingsLazy(
        useMemo(() => bookingFiltersToRequestBodyConverter(filters), [filters]),
        {
            onInvalidPagination: () => {
                setShowInvalidatePagination(true);
            },
        }
    );

    const booking = useMemo(() => {
        return bookings && bookingId
            ? bookings?.find((b: Booking) => b.id === bookingId)
            : undefined;
    }, [bookings, bookingId]);

    const { onScroll } = useLoadOnScroll({
        hasNextPage: !!hasNextPage && !isFetchingNextPage,
        fetchNextPage,
    });

    return (
        <>
            <List>
                <ListMain
                    isLoading={isLoading}
                    SkeletonComponent={BookingListItemSkeleton}
                >
                    <ListTitle title="All Bookings" />
                    <Divider sx={{ mb: 0.25 }} />
                    <Filters
                        values={filters}
                        setValues={setFilters}
                        options={bookingFilters}
                    />
                    <ListItems
                        noItemsText="Filter results will be here"
                        scrollbarProps={{
                            onScrollY: onScroll,
                            containerRef: el => {
                                containerRef.current = el;
                            },
                        }}
                        items={bookings?.length}
                        isLoading={isLoading}
                        entityName="booking"
                        entityNamePlural="bookings"
                        subtractHeight={72 + 1 + (hasFilters ? 116 : 60)}
                    >
                        {bookings?.map(item => (
                            <BookingListItem
                                key={item.id}
                                booking={item}
                                refetch={refetch}
                                isSelected={item.id === bookingId}
                            />
                        ))}
                        {hasNextPage && <BookingListItemSkeleton />}
                    </ListItems>
                </ListMain>
                <ListSidebar
                    isEmpty={!booking}
                    placeholderText="No booking is selected for preview"
                >
                    <SidebarContent
                        title="Booking Preview"
                        isLoading={isLoading}
                    >
                        {booking && (
                            <BookingPreview
                                booking={booking}
                                siblingBookings={[]}
                            />
                        )}
                    </SidebarContent>
                </ListSidebar>
            </List>
            <ConfirmationDialog
                handleConfirm={() => {
                    if (containerRef.current) {
                        containerRef.current.scrollTop = 0;
                    }
                    refetch();
                    setShowInvalidatePagination(false);
                }}
                open={showInvalidatePagination}
                title="The list of bookings has been updated!"
                confirmText="Ok"
                content="The list will be reloaded to keep you up to date."
            />
        </>
    );
}

export default Bookings;
