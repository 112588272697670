import React from 'react';
import { Stack } from '@mui/material';
import { Select } from '@travelity/form';
import { useFormContext } from 'react-hook-form';
import { SourceType } from '@travelity/api';
import { useTranslation } from 'react-i18next';
import SelectReferral from '../select-company/select-referral';
import {
    directSources,
    sourceTypes,
} from '../../views/availability/components/booking-side-form';
import { DataRow } from '../data-row';

export interface BookingSourceFormProps {
    preview?: boolean;
    productId: string;
}

const BookingSourceForm: React.FC<BookingSourceFormProps> = ({
    preview,
    productId,
}) => {
    const { watch } = useFormContext();
    const { t } = useTranslation();
    const value =
        watch('sourceType') === SourceType.DIRECT
            ? t(watch('sourceName') || 'Direct', { ns: 'team' })
            : watch('referral');

    return !preview ? (
        <Stack spacing={2}>
            <Select
                name="sourceType"
                label={t('Booking Source Type')}
                placeholder={t('Select Source Type')}
                width="100%"
                options={sourceTypes}
            />
            {watch('sourceType') === SourceType.DIRECT ? (
                <Select
                    name="sourceName"
                    width="100%"
                    label={t('Booking Source')}
                    placeholder={t('Select Source')}
                    options={directSources}
                />
            ) : (
                <SelectReferral
                    name="referral"
                    width="100%"
                    label="Booking Source"
                    placeholder="Type company, email or name"
                    productId={productId}
                />
            )}
        </Stack>
    ) : (
        <Stack sx={{ py: '3px', ml: '-2px' }} gap={2}>
            <DataRow
                label="Source Type"
                value={t(watch('sourceType'), { ns: 'team' })}
            />
            <DataRow label="Source" value={value} />
        </Stack>
    );
};

export default React.memo(BookingSourceForm);
